import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateString' })
export class DateStringPipe implements PipeTransform {
    transform(dateString: string) {
        if (dateString !== null && dateString !== undefined) {
            return new Date(dateString).toDateString();
        } else {
            return '';
        }
    }
}
