import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/core/service/api.service';
import { JwtService } from './jwt.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(
        private http: HttpClient,
        private jwtService: JwtService,
        private apiService: ApiService,
        private router: Router
    ) {
        const token = jwtService.getToken();
        if (token !== '') {
            const userdata = localStorage.getItem('currentUser');
            this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(userdata));
        } else {
            this.currentUserSubject = new BehaviorSubject<any>(null);
        }
        this.currentUser = this.currentUserSubject.asObservable();

        // if (this.currentUserValue) {
        //     this.currentUser.subscribe(data => {
        //         if (data['id'] == this.currentUserValue['id']) {
        //             this.updateUser(data);
        //         }
        //     });
        // }
    }
    public get currentUserValue(): object {
        return this.currentUserSubject.value;
    }
    public currentUserRelation(): object {
        const relation = localStorage.getItem('userRelation');
        return JSON.parse(relation) || {};
    }
    login(data) {
        // ADD APPCODE
        data['appcode'] = environment.appcode;
        data['timeout'] = 24 * 60 * 60;
        return this.http.post<any>(`${environment.clusterUrl}members/login`, data).
        pipe(
        	map(
        		res => {
        			this.jwtService.saveToken(res['data']['token']);
        			this.jwtService.saveUserLoginInfo(data['email'], data['pwd']);
        			res['data']['id'] = res['data']['uid'];
        			this.updateUser(res['data']);
        			return res;
        		},
        		err => {
        			return err;
        		}
        		),
        	catchError(err => of({error: err.status}))
        );
    }
    updateUser(user) {
        this.apiService.get('GET', '/members/' + user['id']).subscribe(
            data => {
                if (data['status']) {
                    localStorage.removeItem('currentUser');
                    this.jwtService.destroyToken();
                } else {
                    const userdata = data['res']['data'][0];
                    localStorage.setItem('currentUser', JSON.stringify(userdata));
                    this.currentUserSubject.next(userdata);
                }
            });
    }
    updateNode(model, registerdata) {
        this.apiService.get('POST', model, [], {}, registerdata).subscribe(
            data => {});
    }
    checkInfo(memberInfo, type) {
        let model = type + '?filter[where][deleted]=false&filter[where][memberId]=' + memberInfo['id'];
        if (type === 'teachers') {
            model += '&filter[include][0][relation]=major';
        }
        this.apiService.get('GET', model).subscribe(
            data => {
                if (data['res']['data'] && data['res']['data'].length === 0) {
                    this.createTeacherOrStudent(memberInfo, memberInfo['id'], type);
                } else {
                    localStorage.setItem('userRelation', JSON.stringify(data['res']['data'][0]));
                }
            });
    }
    logout() {
        const token = this.jwtService.getToken();
        if (token) {
            this.jwtService.destroyToken();
            localStorage.removeItem('currentUser');
            localStorage.removeItem('userRelation');
            this.currentUserSubject.next(null);
            this.router.navigateByUrl('/');
        }
    }
    createTeacherOrStudent(data, uid, model) {
        const body = {
            name: data['name'],
            email: data['email'],
            phone: data['phone'],
            memberId: uid
        };
        this.apiService.post(model, body).subscribe(
            res => {
                this.checkInfo({id: uid}, model);
            });
    }
    register(data) {
        // ADD FIELD FOR REGISTER
        data['appcode'] = environment.appcode;
        data['photo'] = '';
        data['about'] = '';
        data['gender'] = '';
        data['deleted'] = false;
        data['is_sendmail'] = true;
        delete data['re_password'];
        // ADD FIELD FOR REGISTER
        return this.http.post<any>(`${environment.clusterUrl}members/ext/signup`, data).pipe(
            map(
                res => {
                    this.jwtService.saveToken(res['data']['token']);
                    res['data']['id'] = res['data']['uid'];
                    data['id'] = res['data']['uid'];
                    this.updateNode('members/ext/signup', data);
                    delete data['id'];
                    this.updateUser(res['data']);
                    return res;
                },
                err => {
                    return err;
                }
            ),catchError(err => of({error: err.status}))
        );
    }
    reset_password(formdata) {
        const body = {};
        return this.http.post<any>(`${environment.clusterUrl}members/forget/${environment.appcode}/` + formdata['email'], body).pipe(
            map(
                res => {
                    this.updateNode(`members/forget/${environment.appcode}/` + formdata['email'], res['data']);
                    return res;
                },
                err => {
                    return err;
                }
            ),catchError(err => of({error: err.status}))
        );
    }
    changePassword(id, formdata) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            appcode: environment.appcode,
            token: this.jwtService.getToken() });
        const options = { headers };
        const body = {
            pwd1: formdata['pwd1'],
            pwd2: formdata['pwd2'],
            website: ''
        };
        // tslint:disable-next-line:prefer-conditional-expression
        if (this.currentUserValue['userId'] === environment.coacher || this.currentUserValue['userId'] === environment.coachee) {
            body.website = 'match.edu';
        } else {
            body.website = 'admin.io';
        }
        const modelApi = `members/changePWD/${environment.appcode}/` + id;
        return this.apiService.get('POST', modelApi, {}, {}, body);
    }
}
