import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './masterial.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NoSanitizePipe } from '../pipe/no-sanitize.pipe';
import { DateStringPipe } from '../pipe/date-string.pipe';
import { TimeStringPipe } from '../pipe/time-string.pipe';
import { TextTruncatePipe } from '../pipe/text-truncate.pipe';
import { UserPhotoPipe } from '../pipe/user-photo.pipe';
import { ParseIntPipe } from '../pipe/parse-int.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { HourlyRatePipe } from '../pipe/hourly-rate.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { SelectComponent } from '../components/select/select.component';
import { AlertComponent } from '../components/alert/alert.component';
import { LangService } from '../service/lang.service';
import { ConfirmDialogService } from '../service/confirm-dialog.service';
import { AlertService } from '../service/alert.service';
import { JwtService } from '../service/jwt.service';
import { ApiService } from '../service/api.service';
import { StyleService } from '../service/style.service';
import { NgxPayPalModule } from 'ngx-paypal';
import { EditorModule } from '@tinymce/tinymce-angular';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

@NgModule({
    declarations: [
        UserPhotoPipe,
        TextTruncatePipe,
        NoSanitizePipe,
        DateStringPipe,
        TimeStringPipe,
        ParseIntPipe,
        HourlyRatePipe,
        ConfirmDialogComponent,
        AlertComponent,
        PageNotFoundComponent,
        SelectComponent
    ],
    imports: [
        HttpClientModule,
        CommonModule,
        MaterialModule,
        NgxMaterialTimepickerModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        AngularEditorModule,
        EditorModule,
        NgxPayPalModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        MaterialModule,
        NgxMaterialTimepickerModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        TextTruncatePipe,
        FormsModule,
        NoSanitizePipe,
        DateStringPipe,
        TimeStringPipe,
        ParseIntPipe,
        HourlyRatePipe,
        UserPhotoPipe,
        RouterModule,
        CommonModule,
        TranslateModule,
        AngularEditorModule,
        EditorModule,
        ConfirmDialogComponent,
        AlertComponent,
        SelectComponent,
        PageNotFoundComponent
    ],
    providers: [
        LangService,
        AlertService,
        JwtService,
        ApiService,
        StyleService,
        ConfirmDialogService,
        TranslateService
    ],
    entryComponents: [AlertComponent, ConfirmDialogComponent, SelectComponent],
})
export class ShareModule {}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}