import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { JwtService } from './jwt.service';
import { catchError } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    constructor(
        private http: HttpClient,
        private alertService: AlertService,
        private jwtService: JwtService,
        private router: Router
    ) {}

    private formatErrors(error: any) {
        if (error['status'] && error['status'] === 401) {
            localStorage.removeItem('currentUser');
            this.jwtService.destroyToken();
        }
        return throwError(error.error);
    }

    /* Param available:
    * filter[where][field]=value
    * filter[limit]=value
    * filter[order]=value <ASC|DESC> - /weapons?filter[order]=audibleRange%20DESC&filter[limit]=3
    * filter[skip]=value - skip object from result - change page, page * limit
    */
    private queryBuilder(where = [], filter: object = {limit: 10, page: 1}) {
        let query = '?';
        let whereCount = 0;
        let whereArray = '[' + whereCount + ']';
        if (Array.isArray(where)) {
            if (where.length === 1) {
                whereArray = '';
            }
            where.forEach(item => {
                if (whereArray !== '') {
                    whereArray = '[' + whereCount + ']';
                }
                query += 'filter[where]';
                if (item['ope']) {
                    query += '[' + item['ope'] + ']';
                    query += whereArray + '[' + item['key'] + ']';
                } else if (item['con'] && item['con'] === 'inq') {
                    query += '[' + item['key'] + ']';
                } else {
                    query += '[' + item['key'] + ']';
                }

                if (item['con']) {
                    query += '[' + item['con'] + ']';
                }
                query += '=' + item.value + '&';
                whereCount++;
            });
        } else {
            if (Object.keys(where).length === 1) {
                whereArray = '';
            }
            Object.keys(where).forEach((key) => {
                if (whereArray !== '') {
                    whereArray = '[' + whereCount + ']';
                }
                const item = where[key];
                query += 'filter[where]';
                if (item['ope']) {
                    query += '[' + item['ope'] + ']';
                    query += whereArray + '[' + item['key'] + ']';
                } else if (item['con'] && item['con'] === 'inq') {
                    query += '[' + item['key'] + ']';
                } else {
                    query += '[' + item['key'] + ']';
                }
                if (item['con']) {
                    query += '[' + item['con'] + ']';
                }
                query += '=' + item['value'] + '&';
                whereCount++;
            });
        }
        let count_include = 0;
        Object.keys(filter).forEach((key) => {
            if (key === 'page') {
                // @ts-ignore
                query += 'page=' + (filter[key] + 1) + '&';
            } else if (key === 'limit') {
                query += 'filter[limit]=' + (filter[key] / 2) + '&';
            } else if (key === 'limit2') {
                query += 'filter[limit]=' + filter[key] + '&';
            } else if (key === 'include' || key === 'include2' || key === 'include3') {
                query += 'filter[include][' + count_include + '][relation]=' + filter[key] + '&';
                count_include++;
            } else if (key === 'order') {
                query += 'filter[' + key + '][0]=' + filter[key] + '&';
            } else {
                query += 'filter[' + key + '][0]=' + filter[key] + '&';
            }
        });
        return query.substring(0, query.length - 1);
    }

    get(method: string, model: string, where:any = {}, filter: object = {}, body: object = {}): Observable<any> {
        const whereFilter = where;
        if (Array.isArray(whereFilter)) {
            const deleted = whereFilter.filter(item => item['key'] === 'deleted');
            if (!deleted) {
                whereFilter.push({
                    key: 'deleted',
                    value: false
                });
            }
        } else if (whereFilter['deleted'] === undefined) {
            whereFilter['deleted'] = {
                key: 'deleted',
                value: false
            };
        }
        const bodyData = {
            proxy: environment.proxyUrl,
            method,
            model: model + this.queryBuilder(where, filter),
            headers: null
        };
        if (this.jwtService.getToken() !== '') {
            bodyData.headers = {
                token: this.jwtService.getToken(),
                appcode: environment.appcode
            };
        }
        if (method !== 'GET' && method !== 'DELETE') {
            bodyData['body'] = body;
        }
        return this.http.post(`${environment.proxyClusterUrl}`, bodyData).pipe(catchError(this.formatErrors));
    }

    post(path: string, body: object = {}): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            appcode: environment.appcode,
            token: this.jwtService.getToken() });
        const options = { headers };
        return this.http.post<any>(
            `${environment.clusterUrl}${path}`,
            JSON.stringify(body),
            options
        ).pipe(catchError(this.formatErrors));
    }

    put(path: string, body: object = {}): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            appcode: environment.appcode,
            token: this.jwtService.getToken()
        });
        const options = { headers };
        return this.http.put<any>(
            `${environment.clusterUrl}${path}`,
            JSON.stringify(body),
            options
        ).pipe(catchError(this.formatErrors));
    }

    patch(path: string, body: object = {}): Observable<any> {
        return this.get('PATCH', path, [], {}, body);
    }

    delete(path: string, body: object = {}): Observable<any> {
        return this.get('PATCH', path, [], {}, body);
    }

    deleteForever(path: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            appcode: environment.appcode,
            token: this.jwtService.getToken() });
        const options = { headers };
        return this.http.delete<any>(
            `${environment.clusterUrl}${path}`,
            options
        ).pipe(catchError(this.formatErrors));
    }

    getNewToken() {
        const authInfo = this.jwtService.getUserLoginInfo();
        if (authInfo !== '') {
            const data = {
                email: authInfo.split('|-CODE-|')[0],
                pwd: authInfo.split('|-CODE-|')[1],
            };
            data['appcode'] = environment.appcode;
            this.http.post<any>(`${environment.clusterUrl}members/login`, data).subscribe(res => {
                if (res['data']['token']) {
                    localStorage.setItem('lastRefreshToken', new Date().getTime().toString());
                    this.jwtService.saveToken(res['data']['token']);
                    this.jwtService.saveUserLoginInfo(data['email'], data['pwd']);
                    location.reload();
                } else {
                    this.router.navigateByUrl('/login');
                }
            });
        }
    }
}
