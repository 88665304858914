import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/service/api.service';
import { AlertService } from './alert.service';
import { ErrorMessage, handleData } from '../helper/function-helper';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GateService {
    public apiModel = '';
    public appcode = false;
    private whereAppcode = {
        key: 'applicationId',
        value: environment.appcode
    };
    private whereDelete = {
        key: 'deleted',
        value: false
    };
    constructor(
        public http: HttpClient,
        private apiService: ApiService,
        private alertService: AlertService
    ) {
    }
    getAll() {
        let where = [];
        if (this.appcode) {
            where.push(this.whereAppcode);
        }
        return this.apiService.get('GET', this.apiModel, [where], { limit: 100 });
    }
    getLimit(number: number, where = []) {
        if (this.appcode) {
            where.push(this.whereAppcode);
        }
        where.push(this.whereDelete);
        return this.apiService.get('GET', this.apiModel, where, { limit: number, order: 'updated_dt ASC' });
    }
    countData() {
        let where = [];
        if (this.appcode) {
            where.push(this.whereAppcode);
        }
        return this.apiService.get('GET', this.apiModel + '/count', where, {});
    }
    apiGate(where: any = [], filter = {}) {
        if (this.appcode) {
            const checkApp = where.filter((el) => {
                return el.key === 'applicationId';
            });
            if (checkApp.length === 0) { where.push(this.whereAppcode);}
        }
        return this.apiService.get('GET', this.apiModel, where, filter).pipe(
            retry(1),
            map(res => { return handleData(res, this.apiService, this.alertService) })
        );
    }
    getData(where: any = [], filter = {}) {
        if (this.appcode) {
            const checkApp = where.filter((el) => {
                return el.key === 'applicationId';
            });
            if (checkApp.length === 0) {
                where.push(this.whereAppcode);
            }
        }
        const checkApp = where.filter((el) => {
            return el.key === 'deleted';
        });
        if (checkApp.length === 0) {
            where.push(this.whereDelete);
        }
        console.log(this.apiModel, where, filter)
        return this.apiService.get('GET', this.apiModel, where, filter).pipe(
            retry(1),
            map(res => { return handleData(res, this.apiService, this.alertService) })
        );
    }
    createNew(values: any, callback) {
        delete values.id;
        if (this.appcode) {
            values['applicationId'] = environment.appcode;
        }
        return this.apiService.post(this.apiModel, values).subscribe(
            res => {
                if (res['data']) {
                    this.alertService.open('Add new success!',
                        'bg-success'
                    );
                    callback(true);
                }
            },
            (error) => {
                this.alertService.open(ErrorMessage(error), 'red');
            }
        );
    }
    delete(value, callback) {
        const url = `${this.apiModel}/${value.id}`;
        delete value.id;
        const body = {
            deleted: !value.deleted
        };
        return this.apiService.delete(url, body).subscribe(
            res => {
                if (res['res']['data']) {
                    this.alertService.open(
                        'Update delete status success',
                        'bg-success'
                    );
                    callback(true);
                }
            },
            (error) => {
                this.alertService.open(ErrorMessage(error), 'red');
            }
        );
    }
    deleteForever(value, callback) {
        const url = `${this.apiModel}/${value.id}`;
        return this.apiService.deleteForever(url).subscribe(
            res => {
                if (res['res']['data']) {
                    this.alertService.open(
                        'Delete success',
                        'bg-success'
                    );
                    callback(true);
                }
            },
            (error) => {
                this.alertService.open(ErrorMessage(error), 'red');
            }
        );
    }
    update(value, callback) {
        const id = value.id;
        const url = `${this.apiModel}/${id}`;
        delete value.id;
        if (this.appcode) {
            value['applicationId'] = environment.appcode;
        }
        return this.apiService.patch(url, value).subscribe(
            res => {
                if (res['res']['data']) {
                    this.alertService.open(
                        'Update success',
                        'bg-success'
                    );
                    callback(true);
                }
            },
            (error) => {
                this.alertService.open(ErrorMessage(error), 'red');
            }
        );
    }
}
