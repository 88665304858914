import { Injectable } from '@angular/core';

@Injectable()
export class LangService {
    getLang(): string {
        const lang = window.localStorage.currentLanguage;
        if (lang === undefined) {
            this.saveLang('en');
            return 'en';
        } else {
            return lang;
        }
    }
    saveLang(lang: string) {
        window.localStorage.currentLanguage = lang;
    }
}
