import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-table-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {
  @Input() labelKey = 'label';
  @Input() value = 'id';
  @Input() title = 'name';
  @Input() disabled = false;
  @Input() width = false;
  @Input() options = [];
  @Input() model;
  public style = '';
  public originalOptions: any;
  private timerInput;
  constructor() { }

  ngOnInit() {
    if (this.width) {
      this.style += 'width: ' + this.width + 'px;';
    }
    this.originalOptions = this.options;
  }

  updateSelectData(value) {
    clearTimeout(this.timerInput);
    this.timerInput = setTimeout(() => {
      value = value.toLowerCase();
      this.originalOptions = this.options.filter(option => option[this.value].toLowerCase().startsWith(value));
    }, 500);
  }
}
