import { MediaMatcher } from '@angular/cdk/layout';
import { Title, Meta } from '@angular/platform-browser';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MobileService } from './core/service/mobile.service';
import { HomePageService } from './core/service/home-page.service';
import { GateService } from './core/service/gate.service';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    constructor(
        private mobileService: MobileService,
        private titleService: Title,
        private metaService: Meta,
        private homePageService: HomePageService,
        private gateService: GateService,
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher
    ) {
        this.homePageService.getHomeData();
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }
    mobileQuery: MediaQueryList;
    _mobileQueryListener: () => void;
    opened = false;
    is_loading = false;
    title = 'Coach on Tap';
    ngOnInit(): void {
        this.titleService.setTitle(this.title);
        this.metaService.addTags([
            {name: 'keywords', content: 'Coach on Tap'},
            {name: 'description', content: 'Coach on Tap'},
            {name: 'robots', content: 'index, follow'}
        ]);
        this.loadData();
        this.mobileService.setMobileSubject(this.mobileQuery.matches);
    }
    onResize(e) {
        this.mobileService.setMobileSubject(this.mobileQuery.matches);
    }
    loadData() {
    }
}
