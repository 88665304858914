import { Injectable } from '@angular/core';
import { UserService } from '../service/user.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(private router: Router, private userService: UserService) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
        const currentUser = this.userService.currentUserValue;
        if (currentUser) {
            if (currentUser['userId'] === environment.admin) {
                return true;
            }
        }
        this.router.navigateByUrl('/auth/login');
        return false;
    }
}
