import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from 'src/app/core/share/masterial.module';
import { ShareModule } from 'src/app/core/share/share.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        FormsModule,
        HttpClientModule,
        MaterialModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ShareModule
    ],
    entryComponents: [],
    providers: [
        DatePipe
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {}