import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userPhoto'
})
export class UserPhotoPipe implements PipeTransform {
    transform(user = {}) {
        return (user && user['photo']) || 'assets/images/no-avatar.png';
    }
}
