import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({ name: 'hourlyRate' })
export class HourlyRatePipe implements PipeTransform {
    transform(rateString) {
        let result = '';
        let currentHourlyRate = [];
        if (Array.isArray(rateString)) {
            currentHourlyRate = rateString;
        } else {
            currentHourlyRate.push(rateString);
        }
        environment.hourly_rate.forEach(item => {
                currentHourlyRate.forEach(item2 => {
                if (item.id === item2) {
                    if (result !== '') {
                        result += '<br>';
                    }
                    result += item.value;
                }
            });
        });
        return result;
    }
}
