import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeString' })
export class TimeStringPipe implements PipeTransform {
    transform(dateString: string) {
        if (dateString !== null && dateString !== undefined) {
            const date = new Date(dateString);
            return date.getHours() + ':' + date.getMinutes();
        } else {
            return '';
        }
    }
}
