import { ApiService } from '../service/api.service';
import { AlertService } from '../service/alert.service';
import { throwError } from 'rxjs';

export function handleData(data, api: ApiService, alert: AlertService) {
    let errorMessage = '';
    if (Object.keys(data['res']['error']).length > 0) {
        if (data['res']['error']['statusCode'] === 400) {
            alert.open('Token expired, get new token from server!<br>F5 your screen to reload data!', 'red');
            api.getNewToken();
            errorMessage = 'Token expired, get new token from server!<br>F5 your screen to reload data!';
        } else {
            alert.open('Error Data ' + data['res']['error']['message'], 'red');
            errorMessage = 'Error Data ' + data['res']['error']['message'];
        }
    }
    if (errorMessage != "") {
        data['res']['data'] = [];
    }
    
    return data;
}

export function handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        errorMessage = `Error: ${error.error.message}`;
    } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
}
export function checkField(object, field, result:any = '') {
    if (object !== null && object[field]) {
        return object[field];
    } else {
        return result;
    }
}
export function csvToArray(csvString) {
    const lines = csvString.split('\n');
    const headerValues = lines[0].split(',');
    const dataValues = lines.splice(1).map((dataLine) => { return dataLine.split(','); });
    return dataValues.map((rowValues) => {
        const row = {};
        headerValues.forEach((headerValue, index) => {
            row[index] = (index < rowValues.length) ? rowValues[index] : null;
        });
        return row;
    });
}
export function checkErrorData(data, api: ApiService) {
    if (Object.keys(data['res']['error']).length > 0) {
        if (data['res']['error']['statusCode'] === 400) {
            api.getNewToken();
        }
        data['res']['data'] = [];
    } else if (data['res']['data']) {

    } else {
        data['res']['data'] = [];
    }
    return data;
}
export function photoUser(userInfo) {
    return userInfo['photo'] || 'assets/images/no-avatar.png';
}
export function dateFormat(dateString) {
    if (dateString !== null && dateString !== undefined) {
        return new Date(dateString);
    } else {
        return new Date();
    }
}
export function getDate(dateString) {
    if (dateString !== null && dateString !== undefined) {
        return new Date(dateString).toDateString();
    } else {
        return '';
    }
}
export function getTime(dateString) {
    if (dateString !== null && dateString !== undefined) {
        const date = new Date(dateString);
        return date.getHours() + ':' + date.getMinutes();
    } else {
        return '';
    }
}
export function inArray(arr, key, value) {
    arr.forEach(item => {
        if (item[key] === value) {
            return true;
        }
    });
    return false;
}
export function birthDate(dateString) {
    if (dateString !== null && dateString !== undefined) {
        const date = new Date(dateString);
        return date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();
    } else {
        return new Date();
    }
}
export function ErrorMessage(error) {
    if (error['error'] !== null && error['error'] !== undefined) {
        let errorString = 'Error with your data, please check';
        if (error['error']['details']) {
            error['details'].forEach(item => {
                errorString += '<br>-&nbsp;&nbsp;&nbsp;' + item.path + item.message;
            });
        } else {
            errorString += '<br>-&nbsp;&nbsp;&nbsp;' + error['error']['message'];
        }
        return errorString;
    } else {
        return 'Error';
    }
}
export function moneyFormat(money, currency) {
    switch (currency) {
        case 'USD':
            return '$ ' + formatMoney(money);
            break;
        case 'VND':
            return formatMoney(money) + ' đ';
            break;
    }
}
function formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-' : '';

        const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        const j = (i.length > 3) ? i.length % 3 : 0;
        const decimalValue = (decimalCount ? decimal + Math.abs(amount - parseInt(i)).toFixed(decimalCount).slice(2) : '');
        const thousandValue = (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
        return negativeSign + thousandValue + decimalValue;
    } catch (e) {
        return amount;
    }
}
export function ArrayToObject(arrayData, key, value) {
    let result = {};
    arrayData.forEach(ele => {
        result[ele[key]] = ele[value]
    });
    return result;
}

export function seoURL(str: string) {
    if (str === '' || str === undefined) {
        return str;
    }
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    // Combining Diacritical Marks
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)
    str = str.replace(' ', '-');
    str = str.replace('%20', '-');
    str = str.trim().toLowerCase();
    return str;
}
