import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';
import { AdminGuard } from './core/guard/admin.guard';
const routes: Routes = [
    {
        path: '',
        canActivate: [],
        loadChildren: () => import('./web/web.module').then(m => m.WebModule)
    },
    {
        path: 'admin',
        canActivate: [AdminGuard],
        loadChildren: () =>
            import('./admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
