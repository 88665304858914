// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: '/assets',
    proxyClusterUrl: 'https://proxy.homypeace.com/api/Clusters/request',
    proxyUrl: 'https://proxy.mindxtech.com/',
    clusterUrl: 'https://cluster.homypeace.com/',
    appcode: 'B2AYOru-h',
    coacher: '-XvR5tRI2',
    coachee: 'tVC2JfSby',
    admin: 'TGCPtgRo',
    captcha: '6LdgdfAUAAAAAJ3ea_IOZeHyfGnGDRfyolw4KoxK',
    paypal_client_id: 'AV9dovFTZIce-0D_nqazuYYHTAzFBry5LKDWVt6zrSftORJPlyH7-zKaQx3aKlC4JYV1lOiqZq19Ux_w',
    api_img: '',
    member_role: [
        {
            title: 'Admin',
            value: 'TGCPtgRo'
        },
        {
            title: 'Coachee',
            value: 'tVC2JfSby'
        },
        {
            title: 'Coacher',
            value: '-XvR5tRI2'
        },
        {
            title: 'Unknown',
            value: 'M23R_XBZz'
        }
    ],
    timeline_unit: [
        {
            title: 'Week',
            value: 'Week'
        },
        {
            title: 'Month',
            value: 'Month'
        },
        {
            title: 'Year',
            value: 'Year'
        },
    ],
    currency: [
        {
            title: 'VND',
            value: 'VND'
        },
        {
            title: 'USD',
            value: 'USD'
        }
    ],
    course_process: [
        {
            title: 'Requesting',
            value: '56OtTMDaG'
        },
        {
            title: 'Processing',
            value: 'q6uU4PP8d'
        },
        {
            title: 'Finished',
            value: 'ThBtOQLZO'
        }
    ],
    category_coacher: 'WLVnELOKW',
    category_student: 'I4rtIpQWu',
    default_coacher_membership: '',
    default_student_membership: 'jd0X_RL7a',
    gender: [
        {
            id: 'Male',
            value: 'Male'
        },
        {
            id: 'Female',
            value: 'Female'
        },
        {
            id: 'Prefer not to state',
            value: 'Prefer not to state'
        },
        {
            id: 'Self-identify',
            value: 'Self-identify'
        }
    ],
    age_group: [
        {
            id: 'Under 18 yr',
            value: 'Under 18 of age',
            small: '(Parent permission/notification is required prior to receiving coaching support)'
        },
        {
            id: '18 – 30',
            value: '18 – 30'
        },
        {
            id: '31-40',
            value: '31-40'
        },
        {
            id: '41-50',
            value: '41-50'
        },
        {
            id: '50+',
            value: '50+'
        },
    ],
    age_group_coach: [
        {
            id: '18 – 30',
            value: '18 – 30'
        },
        {
            id: '31-40',
            value: '31-40'
        },
        {
            id: '41-50',
            value: '41-50'
        },
        {
            id: '50+',
            value: '50+'
        },
    ],
    ethnicity: [
        {
            id: 'African',
            value: 'African'
        },
        {
            id: 'Asian/Pacific Islander',
            value: 'Asian/Pacific Islander'
        },
        {
            id: 'Hispanic or Latino',
            value: 'Hispanic or Latino'
        },
        {
            id: 'Indian',
            value: 'Indian'
        },
        {
            id: 'Middle Eastern',
            value: 'Middle Eastern'
        },
        {
            id: 'Native American',
            value: 'Native American'
        },
        {
            id: 'White, non-Hispanic',
            value: 'White, non-Hispanic'
        },
    ],
    coaching_focus: [
        {
            id: 'Life (Personal)',
            value: 'Life (Personal)'
        },
        {
            id: 'Business (Professional)',
            value: 'Business (Professional)'
        },
    ],
    coach_location: [
        {
            id: 'Locally',
            value: 'Locally (within a 30-mile radius from main city may limit your options)'
        },
        {
            id: 'Globally',
            value: 'Globally'
        }
    ],
    coach_style: [
        {
            id: 'Direct',
            value: '<b>Direct</b>: Realistic, Get to the point'
        },
        {
            id: 'Energetic',
            value: '<b>Energetic</b>: Acknowledging, Friendly'
        },
        {
            id: 'Patience',
            value: '<b>Patience</b>: Sensitive, Attentive'
        },
        {
            id: 'Systematic',
            value: '<b>Systematic</b>: Facts, Process'
        }
    ],
    coach_mode: [
        {
            id: 'Virtual video conf',
            value: 'Virtual video conf'
        },
        {
            id: 'Tele-conf',
            value: 'Tele-conf'
        },
        {
            id: 'In-person',
            value: 'In-person'
        },
    ],
    hourly_rate: [
        {
            id: '$',
            value: '$ (5 - 50 $/hr) (Probono starts at $5/hr)'
        },
        {
            id: '$$',
            value: '$$ (51 - 150 $/hr)'
        },
        {
            id: '$$$',
            value: '$$$ (151 - 300 $/hr)'
        },
        {
            id: '$$$$',
            value: '$$$$ (301 - 500 $/hr)'
        },
        {
            id: '*$*',
            value: '*$* (Corporate rates TBD)'
        },
    ],
    coach_package: [
        {
            id: '3 months',
            value: '3 months'
        },
        {
            id: '6 - 9 months',
            value: '6 - 9 months'
        },
        {
            id: '12 - 15 months',
            value: '12 - 15 months'
        },
    ],
    year_experience: [
        {
            id: 1,
            value: '1-3',
            text: '1+'
        },
        {
            id: 3,
            value: '3-5',
            text: '3+'
        },
        {
            id: 5,
            value: '5-10',
            text: '5+'
        },
        {
            id: 11,
            value: '11-15',
            text: '11+'
        },
        {
            id: 16,
            value: '16+',
            text: '16+'
        },
    ],
    campaign: [
        {
            id: 'IwQAcwcpm',
            value: 'Teacher'
        },
        {
            id: 'f_ObPx32A',
            value: 'Student'
        }
    ],
    meet_type: [
        {
            id: 'In person',
            value: 'In person'
        },
        {
            id: 'Video Call',
            value: 'Video Call'
        },
        {
            id: 'Telephone',
            value: 'Telephone'
        },
    ],
    industry_experience: [
        'Business Administration',
        'Business Development',
        'Customer Service',
        'Engineer (R&D)',
        'Finance',
        'Human Resources',
        'IT',
        'Manufacturing',
        'Marketing',
        'Operations',
        'Productions',
        'Public Relations/Corp communication',
        'Sales'
    ],
    major: ['cO3w21bCg', 'xU7RcfbRc', 'wfaOgYIGK'],
    tag_page: 'EagOBwV53',
    tag_setting: 'LjQJnjfML',
    trial_status: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
