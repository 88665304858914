import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { ApiService } from 'src/app/core/service/api.service';
import { ErrorMessage } from 'src/app/core/helper/function-helper';
import { shareReplay, map, switchMap } from 'rxjs/operators';

const REFRESH_INTERVAL = 120000;
const CACHE_SIZE = 1;

@Injectable({
    providedIn: 'root'
})
export class HomePageService {
    public cacheHomeData: Observable<Array<any>>;
    public homeData;
    constructor(
        public http: HttpClient,
        public alertService: AlertService,
        private apiService: ApiService
    ) {}

    getHomeData() {
        if (!this.cacheHomeData) {
            const timer$ = timer(0, REFRESH_INTERVAL);
            this.cacheHomeData = timer$.pipe(
                switchMap(_ => this.apiCallHomeData()),
                shareReplay(CACHE_SIZE)
            );
        }
        return this.cacheHomeData;
    }
    apiCallHomeData() {
        return this.apiService.get('GET', 'posts', [{ key: 'tagId', value: environment.tag_setting }])
            .pipe(
                map(res => res['res']['data'])
            );
    }
    sendContact(data, callback) {
        data['applicationId'] = environment.appcode;
        return this.apiService.post('contacts', data).subscribe(
            res => {
                if (res['data']) {
                    callback(true);
                }
            },
            (error) => {
                this.alertService.open(ErrorMessage(error), 'red');
            }
        );
    }
}
