import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class JwtService {
    constructor(
    ) {
    }
    getToken(): string {
        if (window.localStorage.jwtToken !== undefined) {
            return window.localStorage.jwtToken;
        } else {
            return '';
        }
    }
    getUserLoginInfo() {
        if (window.localStorage.authString !== undefined) {
            const string = atob(window.localStorage.authString);
            return string;
        } else {
            return '';
        }
    }
    saveToken(token: string) {
        window.localStorage.jwtToken = token;
    }
    saveUserLoginInfo(username, password) {
        const string64 = btoa(username + '|-CODE-|' + password);
        window.localStorage.authString = string64;
    }
    destroyToken() {
        window.localStorage.removeItem('jwtToken');
        window.localStorage.removeItem('authString');
    }
}
