import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ConfirmDialogService {
    subcription: Subscription;
    constructor(private dialog: MatDialog) {}
    async openDialog() {
        let status = false;
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: 'Are you sure to change delete status this record ?'
        });
        await dialogRef
            .afterClosed()
            .toPromise()
            .then(result => {
                if (result) {
                    status = true;
                }
            });
        return status;
    }
    async openDialogForever() {
        let status = false;
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: 'Are you sure to delete this record forever?'
        });
        await dialogRef
            .afterClosed()
            .toPromise()
            .then(result => {
                if (result) {
                    status = true;
                }
            });
        return status;
    }
}
